<template>
    <div class="row" v-if="account_type.account_type_id !== account_candidate_teacher">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                                          placeholder="Tìm tên lớp" autocomplete="false"></el-input>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                <el-select @change="getBranches(form.center)" clearable v-model="form.center" filterable class="w-100 mt-3"
                                           placeholder="Trung tâm">
                                    <el-option
                                        v-for="item in centers"
                                        :key="item.id"
                                        :label="item.name"
                                        placeholder="Chọn trung tâm"
                                        :value="item.id">
                                        <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                                           placeholder="Chi nhánh">
                                    <el-option
                                        v-for="item in branches"
                                        :key="item.id"
                                        :label="item.name"
                                        placeholder="Chọn cơ sở"
                                        :value="item.id">
                                        <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </el-col>
                          <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                            <el-select clearable v-model="form.type_class" filterable class="w-100 mt-3"
                                       placeholder="Loại lớp học">
                              <el-option
                                  v-for="item in todos"
                                  :key="item.text"
                                  :label="item.text"
                                  placeholder="Chọn cơ sở"
                                  :value="item.id">
                                <span style="float: left">{{ item.text }}</span>
                              </el-option>
                            </el-select>
                          </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                  <el-select clearable v-model="form.course_type" filterable class="w-100 mt-3"
                                             placeholder="Loại khóa học">
                                      <el-option
                                          v-for="item in courseTypes"
                                          :key="item.id"
                                          :label="item.name"
                                          placeholder="Chọn loại khóa học"
                                          :value="item.id">
                                          <span style="float: left">{{ item.name }}</span>
                                      </el-option>
                                  </el-select>
                              </el-col>

                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                <el-select clearable v-model="form.course_id" filterable class="w-100 mt-3"
                                           placeholder="Khóa học">
                                    <el-option
                                        v-for="item in courses"
                                        :key="item.id"
                                        :label="item.name"
                                        placeholder="Chọn cơ sở"
                                        :value="item.id">
                                        <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                <el-select clearable v-model="form.status_code" filterable class="w-100 mt-3"
                                           placeholder="Trạng thái">
                                    <el-option
                                        v-for="item in status"
                                        :key="item.id"
                                        :label="item.text"
                                        placeholder="Chọn cơ sở"
                                        :value="item.id">
                                        <span style="float: left">{{ item.text }}</span>
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                <el-date-picker
                                    class="w-100 mt-3"
                                    v-model="form.startDate"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    @change="pickStart"
                                    :picker-options="pickerStartOptions"
                                    placeholder="Khai giảng từ ngày">
                                </el-date-picker>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                                <el-date-picker
                                    class="w-100 mt-3"
                                    v-model="form.endDate"
                                    type="date"
                                    ref="picker"
                                    :onPick="pickEnd"
                                    format="yyyy-MM-dd"
                                    @change="pickEnd"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerEndOptions"
                                    placeholder="Khai giảng đến ngày">
                                </el-date-picker>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                                <button :disabled="loading" class="btn btn-primary mt-3" @click="submit"><i
                                    v-bind:class="[loading ? 'el-icon-loading' : 'fa fa-search']"></i> Tìm kiếm
                                </button>
                            </el-col>
                        </el-row>
                        <el-table
                            :data="tableData"
                            stripe
                            v-loading="loading"
                            border
                            class="table-hover mt-5"
                            style="width: 100%">
                            <el-table-column label="#" type="index" width="50" :index="indexMethod">
                            </el-table-column>
                            <el-table-column
                                prop="id"
                                label="ID Lớp"
                                width="100">
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="Tên lớp"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="center.name"
                                width="180"
                                label="Trung tâm">
                                <template v-slot="scope">
                                    <span class="text-break">{{ scope.row.center ? scope.row.center.name : '' }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Chi nhánh" >
                                <template v-slot="scope">
                                    <span style="margin-left: 10px" class="text-break">{{ scope.row.branch ? scope.row.branch.name : '' }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Loại lớp học" >
                              <template v-slot="scope">
                                <span style="margin-left: 10px">{{ getType(scope.row.type) }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="Khóa học">
                                <template v-slot="scope">
                                    <span style="margin-left: 10px; word-break: break-word" >{{ scope.row.course ? scope.row.course.name : '' }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Ngày khai giảng">
                                <template v-slot="scope">
                                    <span style="margin-left: 10px"><i class="fas fa-clock"></i> {{ scope.row.start_date | formatDate }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Trạng thái" >
                                <template v-slot="scope">
                                    <button name="" type="button" class="btn btn-xs btn-default cursor-default text-break" v-if="scope.row.status_code === 1">
                                        <span class="fas fa-circle mr-1 color-default-900"></span>
                                      Sắp khai giảng
                                    </button>
                                    <button name="" type="button" class="btn btn-xs btn-success cursor-default text-break" v-if="scope.row.status_code === 2">
                                          <span class="fas fa-circle mr-1 color-success-700"></span>
                                      Đang học
                                      </button>
                                    <button name="" type="button" class="btn btn-xs btn-warning cursor-default text-break" v-if="scope.row.status_code === 3">
                                          <span class="fas fa-circle mr-1  color-warning-900"></span>
                                       Kết thúc
                                      </button>
                                </template>
                            </el-table-column>
                            <el-table-column width="650" label="Hành động" >
                                <template v-slot="scope">
                                    <a href="javascript:void(0)" @click="openRouteSchedule(scope.row.id, scope.row.name )" class="small btn btn-primary mr-1 mt-2 mb-2">
                                        <i class="fa fa-th"></i> Lộ trình
                                    </a>

                                    <button class="btn btn-info mr-2  mt-2mb-2" @click="openDialogViewDetail(scope.row.id)"><i class="fa fa-eye"></i> Xem chi tiết</button>

                                    <a v-if="scope.row.type != 'school_review'" href="javascript:void(0)" @click="openRouteTeam(scope.row.id, scope.row.name ,scope.row.center_id)" class="small btn btn-danger mr-1 mt-2 mb-2">
                                        <i class="fa fa-flag"></i> Tổ chức lớp
                                    </a>
<!--                                  <a href="javascript:void(0)" @click="openRouteTeamKid(scope.row.id, scope.row.name,scope.row )" class="small btn btn-danger mr-1 mt-2 mb-2">-->
<!--                                    <i class="fa fa-flag"></i> Tổ chức lớp kid-->
<!--                                  </a>-->
                                     <a  href="javascript:void(0)" @click="openRouteUpLoad(scope.row.id, scope.row.name )"  class="small btn btn-success mr-1 mt-2 mb-2 border-0" style="background-color:green">
                                         <i class="fa fa-camera"></i> Tư liệu
                                     </a>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="edutalk-paging">
                            <div class="block" v-if="paging.total > 25">
                                <!--eslint-disable-->
                                <el-pagination
                                    background
                                    @current-change="handleCurrentChange"
                                    :current-page.sync="paging.current_page"
                                    :page-size="25"
                                    layout="prev, pager, next"
                                    :total="paging.total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Điểm danh -->
        <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogViewDetail">
            <detail-class @close="closeDialog()" :data="dialog.detail" v-if="dialogViewDetail"></detail-class>
        </el-dialog>
      
<!--        <div id="overlay" @click.self="off()">-->
<!--            <div id="text" class="background-card-teacher" style="height: 700px; margin: auto; background-repeat: no-repeat;">-->

<!--                <div class="message">-->
<!--                    <img :src="publicPath + '/media/new-year/button-close.png'" class="button-close-office" @click.self="off()">-->
<!--                    <div class="tru-so">-->
<!--                        <p class="title-teacher-card" style="font-size: 23px; font-weight: 900; text-align: center;">-->
<!--                            EDUTALK TỰ HÀO VỀ THẦY CÔ-->
<!--                        </p>-->
<!--                        <p class="message-title">-->
<!--                            {{ this.currentUser.profile.center.id == 295 ? 'Coach' : 'Mentor' }} {{ this.currentUser.name ?? 'Bạn' }} thân mến,-->
<!--                        </p>-->
<!--                        <div class="message-content">-->
<!--                            <p>-->
<!--                                2023 khép lại với rất nhiều vinh quang và thành tích ấn tượng, Edutalk trân trọng và tự hào vì những nỗ lực không ngừng, sự nhiệt huyết và tận tâm của Thầy Cô. 2024 sắp tới, chúc cho chúng ta sẽ có thật nhiều khoảnh khắc tuyệt vời cùng nhau.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                Edutalk tin rằng, với sự đam mê, tâm huyết và sáng tạo không ngừng để truyền cảm hứng cho Học viên, Thầy Cô sẽ là những người quan trọng đóng góp vào sự thành công của Edutalk với sứ mệnh giáo dục <span class="message-important"> "Khiến thế giới phải nhắc tới và tự hào về trí tuệ và con người Việt Nam"</span>-->
<!--                            </p>-->
<!--                            <p style="text-align: center">-->
<!--                                Chúc Quý thầy cô một năm mới tràn ngập niềm vui, hạnh phúc và đầy ắp thành công!-->
<!--                            </p>-->
<!--                            <p class="message-signature">-->
<!--                                Trân trọng!-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
    <div id="dialog-fail" class="col-xl-4" v-else>
        <!--  Thông báo ứng viên giảng viên đã fail đào tạo -->
        <el-dialog style="padding: 15px 15px 5px" title="Thông báo" class="responsive" :visible.sync="dialogFailCandidate">
            <h4 style="word-break: break-word" class="border-top border-black pt-3">Bạn đã hết thời hạn đào tạo nên không thể truy cập khóa học. 
                Vui lòng liên hệ với quản lý giảng viên để được gia hạn đào tạo</h4>
            <div slot="footer" class="dialog-footer text-center">
                <el-button size="small" type="warning" @click="checkCandidate">Đã hiểu</el-button>
              </div>
        </el-dialog>

        <div class="bg-warning-400 rounded overflow-hidden position-relative text-white mb-g">
            <div class="">
              <h3 class="display-4 d-block l-h-n m-0 fw-500 p-3">
                {{ account_type.account_type.name }}
                <small class="m-0 l-h-n">Vị trí hiện tại</small>
              </h3>
            </div>
            <i class="fal fa-gem position-absolute pos-right pos-bottom opacity-15 mb-5 mr-3"
               style="font-size: 5rem;"></i>
            <div style="background-color: #e5b552; color: #e5b552; width:100%; height: 15px"></div>
          </div>
    </div>
</template>
<!--<style>-->
<!--    .background-card-teacher {-->
<!--        background-image: url('/media/new-year/rsz_giang-vien.jpg');-->
<!--    }-->
<!--    .message-title {-->
<!--        font-style: italic;-->
<!--        font-size: 19px;-->
<!--        font-weight: 600;-->
<!--        text-align: center;-->
<!--    }-->
<!--    .message-content {-->
<!--        font-size: 16px;-->
<!--        line-height: 1.5;-->
<!--    }-->
<!--    .message-important {-->
<!--        font-style: italic;-->
<!--        font-size: 17px;-->
<!--        font-weight: 600;-->
<!--    }-->
<!--    .message-signature {-->
<!--        font-style: italic;-->
<!--        font-size: 19px;-->
<!--        font-weight: 600;-->
<!--        text-align: center;-->
<!--    }-->
<!--    #overlay {-->
<!--        position: fixed;-->
<!--        display: none;-->
<!--        width: 100%;-->
<!--        height: 100%;-->
<!--        top: 0;-->
<!--        left: 0;-->
<!--        right: 0;-->
<!--        bottom: 0;-->
<!--        background-color: rgba(0,0,0,0.5);-->
<!--        z-index: 2;-->
<!--        cursor: pointer;-->
<!--    }-->
<!--    #text {-->
<!--        width: 23%!important;-->
<!--        margin-top: 12%!important;-->
<!--        word-wrap: break-word;-->
<!--        word-break: break-all;-->
<!--    }-->
<!--    .message {-->
<!--        overflow-wrap: break-word;-->
<!--        word-wrap: break-word;-->
<!--        top: 12%;-->
<!--        width: 100%;-->
<!--        word-break: break-word;-->
<!--        padding: 5%;-->
<!--        padding-top: 32%;-->
<!--        color: #452c87;-->
<!--        text-align: justify;-->
<!--    }-->
<!--    .button-close-office {-->
<!--        float: right;-->
<!--        margin-top: -39%;-->
<!--        margin-right: -9%;-->
<!--    }-->
<!--    .title-teacher-card {-->
<!--      font-size: 21px!important;-->
<!--    }-->
<!--    .message-title {-->
<!--      font-size: 16px;-->
<!--    }-->
<!--    .message-content {-->
<!--      font-size: 14px;-->
<!--      line-height: 1.3;-->
<!--    }-->
<!--    .message-important {-->
<!--      font-size: 15px;-->
<!--    }-->
<!--    .message-signature {-->
<!--      font-size: 16px;-->
<!--    }-->
<!--    @media screen and (min-width: 2560px) {-->
<!--        #text {-->
<!--            width: 23%!important;-->
<!--        }-->
<!--        .message {-->
<!--            width: 74%!important;-->
<!--            padding-top: 25%;-->
<!--        }-->
<!--        .button-close-office {-->
<!--            margin-top: -43%;-->
<!--            margin-right: -12%;-->
<!--        }-->
<!--    }-->

<!--    @media screen and (max-width: 1925px)  {-->
<!--        #text {-->
<!--            width: 24.5%!important;-->
<!--            margin-top: 9%!important;-->
<!--        }-->
<!--        .message {-->
<!--            width: 96%;-->
<!--        }-->
<!--        .button-close-office {-->
<!--            float: right;-->
<!--            margin-top: -41%!important;-->
<!--            margin-right: -7%!important;-->
<!--        }-->
<!--    }-->

<!--    @media screen and (max-width: 1440px)  {-->
<!--        #text {-->
<!--            width: 32%!important;-->
<!--            margin-top: 10%!important;-->
<!--            /*left: 32%;*/-->
<!--        }-->
<!--        .message {-->
<!--          width: 96%;-->
<!--        }-->
<!--        .button-close-office {-->
<!--          margin-right: -8%!important;-->
<!--        }-->
<!--    }-->

<!--    @media screen and (max-width: 1024px) {-->
<!--        #text {-->
<!--            width: 43%!important;-->
<!--            margin-top: 14%!important;-->
<!--        }-->
<!--        .message {-->
<!--            width: 100%;-->
<!--            padding-top: 30%;-->
<!--        }-->
<!--        .button-close-office {-->
<!--            margin-top: -37%!important;-->
<!--        }-->
<!--    }-->

<!--    @media screen and (max-width: 992px) {-->
<!--        #text {-->
<!--            width: 60%!important;-->
<!--            top: 9%;-->
<!--        }-->
<!--        .message {-->
<!--            width: 96%!important;-->
<!--        }-->
<!--        .button-close-office {-->
<!--            margin-right: -9%!important;-->
<!--        }-->
<!--    }-->

<!--  /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */-->
<!--    @media screen and (max-width: 600px) {-->
<!--        .background-card-teacher {-->
<!--            background-image: url('/media/new-year/rsz_teacher-mobile.jpg');-->
<!--        }-->
<!--        #text {-->
<!--            width: 96%!important;-->
<!--            left: 2%;-->
<!--            top: 0;-->
<!--            background-position: center;-->
<!--        }-->
<!--        .message {-->
<!--            width: 100%;-->
<!--            padding-top: 40%;-->
<!--        }-->
<!--        .title-teacher-card {-->
<!--            font-size: 20px!important;-->
<!--        }-->
<!--        .message-title {-->
<!--            font-size: 15px;-->
<!--        }-->
<!--        .message-content {-->
<!--            font-size: 13px;-->
<!--            line-height: 1.3;-->
<!--        }-->
<!--        .message-important {-->
<!--            font-size: 14px;-->
<!--        }-->
<!--        .message-signature {-->
<!--            font-size: 15px;-->
<!--        }-->
<!--        .button-close-office {-->
<!--            margin-top: -39%!important;-->
<!--            margin-right: -14%!important;-->
<!--        }-->
<!--    }-->
<!--    @media screen and (max-width: 320px) {-->
<!--        .button-close-office {-->
<!--            margin-top: -37%!important;-->
<!--            margin-right: -15%!important;-->
<!--        }-->
<!--    }-->
<!--</style>-->
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {BRANCH_LIST, TYPE_COURSE, COURSES, CLASSROOMS, CLASSROOM_DETAIL, CALL_TITLE, GET_CENTER_BY_TEACHER_ID, CHECK_LOGIN_TEACHER} from "@/core/services/store/service.module";
import {LOGOUT} from "@/core/services/store/auth.module";
import DetailClass from "@/views/pages/classrooms/detail-class";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
const _ = deepdash(lodash);
import {mapGetters} from "vuex";

export default {
    components: {
        DetailClass
    },

    data() {
        return {
            pickerStartOptions: {
                disabledDate: this.disabledStartDate
            },
            pickerEndOptions: {
                disabledDate: this.disabledEndDate
            },
            paging: {
                current_page: 1,
                per_page: 25,
                total: 0,
            },
            loading: false,
            currentPage: 1,
            options: [],
            currentDate: null,
            form: {
                name: '',
                branch_id: '',
                course_type: '',
                course_id: '',
                startDate: null,
                endDate: null,
                type_class: '',
                status_code: '',
                center: ''
            },
            fromDate: null,
            toDate: null,
            branches: [],
            centers: [],
            courseTypes: [],
            courses: [],
            dialogViewDetail: false,
            dialogFailCandidate: false,
            dialog: {
                title: null,
                detail: null
            },
            status: [
                {'id': 1, 'text': 'Sắp khai giảng'},
                {'id': 2, 'text': 'Đang học'},
                {'id': 3, 'text': 'Kêt thúc'},
            ],
            account_type: '',
            tableData: [],
            todos: [
              { id: 'trial', text: 'Học thử' },
              { id: 'official', text: 'Học chính' },
              { id: 'school_review', text: 'School Review' }
            ],
            account_candidate_teacher: 33,
            CAMBRIDGE_CENTER_ID: 530
            // publicPath: process.env.VUE_APP_BASE_URL,
            // srcImageNewYear: 'media/new-year/rsz_giang-vien.jpg'
        }
    },
    async mounted() {
        this.account_type = this.currentUser.profile;
        if(this.account_type.account_type_id == this.account_candidate_teacher) {
            await this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Dashboard", icon: 'far fa-list'} ])
        }else {
            await this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý lớp học", icon: 'far fa-list'}
        ]);
        }
        await this.checkLoginTeacher();
        await this.getBranches();
        await this.getCourse();
        await this.getTypeCourse();
        await this.querySever();
        await this.getCenterByTeacherId();
        
        this.currentDate = this.$moment().format('YYYY-MM-DD')
    },
    methods: {
        closeDialog() {
            this.dialogViewDetail = false;
        },

        openDialogViewDetail(id) {
            this.dialogViewDetail = true;
            this.dialog.title = 'Chi tiết lớp';
            this.getClassroom(id);
        },

        pickStart(date) {
            this.fromDate = null;
            if (date) {
                this.fromDate = new Date(date);
            }
        },

        disabledStartDate(date) {
            if (this.toDate) {
                return this.toDate < date
            }
            return date > new Date();
        },

        pickEnd(date) {
            this.toDate = null;
            if (date) {
                this.toDate = new Date(date);
            }
        },

        disabledEndDate(date) {
            if (this.fromDate) {
                return this.fromDate > date || date > new Date();
            }
            return date > new Date();
        },

        getCenterByTeacherId() {
        let teacher_id = this.account_type.user_id;
        return this.$store.dispatch(GET_CENTER_BY_TEACHER_ID, {
            id: teacher_id
        })
            .then((res) => {
                this.centers = res.data;
            }).catch((err) => {
  
            })
        },

        checkLoginTeacher() {
            let teacher_id = this.account_type.user_id;
            return this.$store.dispatch(CHECK_LOGIN_TEACHER, {
                id: teacher_id
            })
            .then((res) => {
            if((res.data.type && res.data.status) == 2 && teacher_id == res.data.user_id) {
               this.dialogFailCandidate = true;
              const setLogout = setTimeout(() => {
                    this.checkCandidate()
                }, 8000);
            }else {
                return false;
            }
            }).catch((err) => {
            })
        },

        checkCandidate() {
            this.$store.dispatch(LOGOUT).then(() => {
                localStorage.removeItem('user');
                localStorage.removeItem('_token');
                this.$router.push({name: 'login'})
            });
            clearTimeout(setLogout);
        },

        handleCurrentChange(val) {
            this.currentPage = val;
            this.querySever({page: val});
        },

        indexMethod(index) {
            return 25 * (this.currentPage - 1) + (index + 1);
        },

        getBranches(id) {
            let id_center =  id ? id : '';
            this.form.branch_id = '';
            return this.$store.dispatch(BRANCH_LIST,{ id_center}) 
                .then((response) => {
                    if (response.status === 422) {
                        return this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                        this.branches = response.data;
                        return response.data;
                    }
                })
        },

        getClassroom(id) {
            let params = { id: id };
            this.$store.dispatch(CLASSROOM_DETAIL, params)
                .then((response) => {
                    if (response.status === 422) {
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        this.dialog.detail = {}
                    } else {
                        this.dialog.detail = response.data;
                    }
                })
        },

        getTypeCourse() {
            return this.$store.dispatch(TYPE_COURSE)
                .then((response) => {
                    if (response.status === 422) {
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        return [];
                    } else {
                        this.courseTypes = response.data;
                        return response.data;
                    }
                })
        },

        getCourse() {
            let params = {};
            if (this.form.course_type) {
                params = _.merge(params, {type_id: this.form.course_type})
            }
            return this.$store.dispatch(COURSES, params)
                .then((response) => {
                    if (response.status === 422) {
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        return [];
                    } else {
                        this.courses = response.data;
                        return response.data;
                    }
                })
        },

        submit() {
            this.paging.current_page = 1;
            this.querySever();
        },

        querySever(customProperties) {
            this.loading = true;
            let params = this.mergeParams(customProperties);
            this.currentPage = 1;
            this.$store.dispatch(CLASSROOMS, params)
                .then((response) => {
                    if (response.status === 422) {
                        this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                        this.tableData = response.data.data;
                        this.tableData = this.tableData.sort((a, b) =>  a.status_code - b.status_code || new Date(a.start_date) - new Date(b.start_date));
                        this.paging = { current_page: response.data.current_page, total:  response.data.total }
                    }
                    this.loading = false;
                    // document.getElementById("overlay").style.display = "block";
                })
        },

        mergeParams(customProperties) {
            let params = {
                page: this.paging.current_page,
                per_page: this.paging.per_page,
                view_as: this.form.view_as,
            };
            if (this.form.name) {
                params = _.merge(params, {name: this.form.name})
            }

            if (this.form.branch_id) {
                params = _.merge(params, {branch: this.form.branch_id})
            }

            if (this.form.course_id) {
                params = _.merge(params, {course: this.form.course_id})
            }

            if (this.form.status_code) {
                params = _.merge(params, {status_code: this.form.status_code})
            }

            if (this.form.startDate) {
                params = _.merge(params, {start_date: this.form.startDate})
            }

            if (this.form.endDate) {
                params = _.merge(params, {end_date: this.form.endDate})
            }
            if (this.form.course_type) {
                params = _.merge(params, {course_type: this.form.course_type})
            }
            if (this.form.center) {
                params = _.merge(params, {center_id: this.form.center})
            }
          if (this.form.type_class) {
            params = _.merge(params, {type_class: this.form.type_class})
          }
            params = _.merge(params, customProperties);
            return params;
        },

        openRouteSchedule(id, name) {
            this.$router.push({ name: 'schedule', params: { id: id }});
            this.$store.dispatch(CALL_TITLE, name)
        },
        openRouteTeam(id, name,center) {
          if(center === this.CAMBRIDGE_CENTER_ID){
            this.$router.push({ name: 'team-kid', params: { id: id }});
            this.$store.dispatch(CALL_TITLE, name)
            return;
          }
          this.$router.push({ name: 'team', params: { id: id }});
            this.$store.dispatch(CALL_TITLE, name)
        },
        openRouteUpLoad(id, name) {
            this.$router.push({ name: 'upload', params: { id: id }});
            this.$store.dispatch(CALL_TITLE, name)
        },
        getType(type) {
            switch (type) {
                case 'trial':
                    return 'Học thử';
                case 'official':
                    return 'Học chính';
                case 'school_review':
                    return 'School Review';
            }
        }
        // off() {
        //     document.getElementById("overlay").style.display = "none";
        // },
    },
    computed: {
         ...mapGetters(['currentUser'])
    }

}
</script>

<style>
th .cell{
  word-break: normal !important;
}

#dialog-fail .el-dialog__header {
    padding: 20px  20px 0px;
}
</style>
