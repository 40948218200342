<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label class="form-label">Tên lớp </label>
                    <input type="text" class="form-control"  placeholder="Tên lớp"
                           autocomplete="false" :value="form.name">
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label">Khóa học</label>
                    <input type="text" class="form-control"  placeholder="Tên lớp"
                           autocomplete="false" :value="form.course_name">
                </div>
                <div class="col-md-6 mb-3">
                    <label class="form-label">Sĩ số </label>
                    <input type="text" class="form-control"  placeholder="Sĩ số"
                           autocomplete="false" :value="form.number_student">
                </div>
                <div class="col-md-6 mb-3">
                    <label class="form-label">Số bài học</label>
                    <input type="text" class="form-control"  placeholder="Số bài học"
                           autocomplete="false" :value="form.number_lesson">
                </div>
                <div class="col-md-6 mb-3">
                    <label class="form-label">Số bài kiểm tra</label>
                    <input type="text" class="form-control"  placeholder="Số bài kiểm tra"
                           autocomplete="false" :value="form.number_exam">
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label">Chi nhánh</label>
                    <input type="text" class="form-control"  placeholder="Chi nhánh"
                           autocomplete="false" :value="form.branch_name">
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label">Trợ giảng</label>
                    <input type="text" class="form-control"  placeholder="Trợ giảng"
                           autocomplete="false" :value="form.tutors_name">
                </div>
                <div class="col-md-6 mb-3">
                    <label class="form-label">Phòng học</label>
                    <input type="text" class="form-control"  placeholder="Phòng học"
                           autocomplete="false" :value="form.room_name">
                </div>
                <div class="col-md-6 mb-3">
                    <label class="form-label">Ngày kết thúc dự kiến</label>
                    <el-date-picker class="w-100" :value="form.finish_time" type="date" placeholder="Thời gian dự kiến kết thúc"> </el-date-picker>
                </div>
                
                <div class="col-md-6 mb-3">
                    <label class="form-label">Hình thức học</label>
                    <p type="text" class="form-control"  placeholder=""
                           autocomplete="false">{{ form.form_study }}</p>
                </div>
                
                <div class="col-md-6 mb-3" v-if="form.form_study == 'online'">
                    <label class="form-label">Zoom ID</label>
                    <p type="text" class="form-control"  placeholder="Zoom ID" 
                           autocomplete="false">{{ form.zoom_id }}</p>
                </div>
                <div class="col-md-6 mb-3" v-if="form.form_study == 'online'">
                    <label class="form-label">Zoom password</label>
                    <p type="text" class="form-control"  placeholder="Zoom password"
                           autocomplete="false">{{ form.zoom_id }}</p>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
                <button class="btn btn-default mr-2" type="button" @click="close">
                    <i v-if="loading" class="el-icon-loading"></i> Đóng
                </button>
            </div>

        </form>
    </ValidationObserver>
</template>

<script>

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

export default {
    name: 'detail-class',
    props: {
        data: Object,
    },
    data() {
        return {
            form: {
                name: '',
                room: ''
            },
          loading : true
        }
    },
    mounted() {
        this.form = this.data;
    },
    watch: {
        data (value) {
            this.form = value;
            this.loading = false;
        }
    },
    methods: {
        summit() {

        },
        close: function () {
            this.$emit('close');
        },
    },
}
</script>
